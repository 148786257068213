import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrl: './number-filter.component.scss'
})
export class NumberFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;
  matchOptions: SelectItem[] = [{ label: 'Equals', value: 'equals' },
  { label: 'Not Equals', value: 'notEquals' },
    // { label: 'Greater Than', value: 'greaterThan' },
    // { label: 'Greater Than Or Equal To', value: 'greaterThanOrEqualTo' },
    // { label: 'Less Than', value: 'lessThan' },
    // { label: 'Less Than Or Equal To', value: 'lessThanOrEqualTo' }
  ];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }
  initInput() {
    this.dropDownInput = {
      componentType: NumberInputComponent,
      options: {
        name: 'numbr',
        label: '',
        placeholder: '',
        control: new FormControl(null),
      },
    };
  }
}
