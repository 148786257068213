import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import { BaseForm, getModuleKeywordByCode, IDynamicComponent } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import {
  BaseFieldDefinitionsService,
  BaseRequestControllerService,
  ToastService,
  ViewModeService,
} from '@shared/services';
import { LanguageDataService } from 'app/modules/global-configuration/services/data/language-data.service';
import { omit } from 'lodash-es';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-languages-popup-form',
  templateUrl: './languages-popup-form.component.html',
  styleUrl: './languages-popup-form.component.scss',
})
export class LanguagesPopupFormComponent extends BaseForm<any> implements OnInit {
  dataService: BaseRequestControllerService<any>;
  mappingService: BaseFieldDefinitionsService;
  options: any;
  textFields: IDynamicComponent[] = [];
  oldTextFields: IDynamicComponent[] = [];
  newTextFields: IDynamicComponent[] = [];
  listOfTranslatableFields = [BasicInputComponent, TextEditorComponent];
  originalData: any;
  itemCode: string;
  viewOnlyForm: FormGroup = new FormGroup({});
  currentLangData;
  newFieldsLangFile = null;
  constructor(
    public viewContainerRef: ViewContainerRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public toast: ToastService,
    public viewModeService: ViewModeService,
    public localeService: LocaleService,
    private languageDataService: LanguageDataService
  ) {
    super(viewModeService, getModuleKeywordByCode(config?.data?.itemCode) as any);
    this.initForms();

    this.dataService = this.config?.data?.dataService;
    this.mappingService = this.config?.data?.mappingService;
    this.originalData = this.config?.data?.originalData ?? this.originalData;
    this.itemCode = this.config?.data?.itemCode ?? this.itemCode;
    this.formData = this.config?.data?.formData ?? this.data;
    this.options = this.config?.data?.options ?? this.options;
    this.formModuleKeyword = getModuleKeywordByCode(this.config?.data?.itemCode) as any;
    this.initDynamicFields();
    this.fetchLang();
  }

  ngOnInit(): void {
    this.submitActionSubscriber();
  }
  fetchLang() {
    if (this.data?.langCode) {
      const lang = this.languageDataService?.allLanguages?.find((x) => x.langCode == this.data?.langCode);
      const requestCode = lang ? lang.code : this.data?.langCode;
      forkJoin({
        lang: lang ? of({ data: lang }) : this.languageDataService.getByIdOrCode(requestCode),
        langFile: this.languageDataService.downloadLanguageFileAsTextResponse(requestCode, {
          extras: { cacheKey: 'LanguageFile' + requestCode },
          showLoading: true,
          showMsg: false,
        }),
      }).subscribe({
        next: async (res) => {
          this.currentLangData = res?.lang?.data;
          const parsedCsv = await this.languageDataService.parseCsvText(res?.langFile);
          const langFileObj = this.localeService.transformArrayToObject(parsedCsv);
          this.newFieldsLangFile = langFileObj;
          console.log(langFileObj);
          this.initDynamicFields();
        },
        error: (err) => {},
      });
    }
    this.formGroup.controls.langCode.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((langCode) => {
      const lang = this.languageDataService?.allLanguages?.find((x) => x.langCode == langCode);
      const requestCode = lang ? lang.code : langCode;
      forkJoin({
        lang: lang ? of({ data: lang }) : this.languageDataService.getByIdOrCode(requestCode),
        langFile: this.languageDataService.downloadLanguageFileAsTextResponse(requestCode, {
          extras: { cacheKey: 'LanguageFile' + requestCode },
          showLoading: true,
          showMsg: false,
        }),
      }).subscribe({
        next: async (res) => {
          this.currentLangData = res?.lang?.data;
          const parsedCsv = await this.languageDataService.parseCsvText(res?.langFile);
          const langFileObj = this.localeService.transformArrayToObject(parsedCsv);
          this.newFieldsLangFile = langFileObj;
          console.log(langFileObj);
          this.initDynamicFields();
        },
        error: (err) => {},
      });
      // this.languageDataService.getByIdOrCode(langCode).subscribe({
      //   next: (res) => {
      //     this.currentLangData = res?.data;
      //   },
      //   error: (err) => {},
      // });
    });
  }
  submitActionSubscriber() {
    this.formSubmit.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      console.log(data);
      if (this.data) {
        this.dataService
          .updateTranslation(this.itemCode, {
            langCode: data?.langCode,
            translation: { ...omit(data, 'langCode') },
          })
          .subscribe((res) => {
            this.ref.close(true);
          });
      } else {
        this.dataService
          .addTranslation(this.itemCode, {
            langCode: data?.langCode,
            translation: { ...omit(data, 'langCode') },
          })
          .subscribe((res) => {
            this.ref.close(true);
          });
      }
    });
  }
  getData() {
    return { ...this.formGroup.getRawValue() };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      langCode: new FormControl(null, Validators.required),
    });
  }
  initForms() {
    this.viewOnlyForm = new FormGroup({
      originalLanguage: new FormControl({ value: null, disabled: true }),
    });
    this.initFormStructure();
  }
  initDynamicFields() {
    this.textFields = this.mappingService?.formFields
      ?.filter((x) => {
        const ret = x.componentType == BasicInputComponent || x.componentType == TextEditorComponent;
        if (ret) {
          const key = x?.options?.name;
          this.viewOnlyForm.addControl(key, new FormControl({ value: this.originalData?.[key], disabled: true }));
          this.formGroup.addControl(key, new FormControl(this.data?.[key]));
        }
        return ret;
      })
      ?.map((field) => {
        return {
          ...field,
          options: {
            ...field?.options,
            placeholder: field?.options?.name
              ? this.localeService.translate(
                  `${this.moduleFieldString}.${field?.options?.name}.placeholder`,
                  field?.options?.placeholder
                )
              : field?.options?.placeholder,
            label: field?.options?.name
              ? this.localeService.translate(
                  `${this.moduleFieldString}.${field?.options?.name}.label`,
                  field?.options?.label
                )
              : field?.options?.label,
          },
        } as IDynamicComponent;
      });
    this.oldTextFields = this.textFields.map((x) => {
      return {
        ...x,
        options: { ...x.options, control: this.viewOnlyForm?.controls?.[x?.options?.name], showLabelInViewMode: true },
      } as IDynamicComponent;
    });
    this.viewOnlyForm.controls.originalLanguage.patchValue(this.originalData?.originalLanguage);
    this.newTextFields = this.textFields.map((x) => {
      return {
        ...x,
        options: {
          ...x.options,
          placeholder: x?.options?.name
            ? this.localeService.translate(
                `${this.moduleFieldString}.${x?.options?.name}.placeholder`,
                x?.options?.placeholder,
                this.newFieldsLangFile
              )
            : x?.options?.placeholder,
          label: x?.options?.name
            ? this.localeService.translate(
                `${this.moduleFieldString}.${x?.options?.name}.label`,
                x?.options?.label,
                this.newFieldsLangFile
              )
            : x?.options?.label,
          control: this.formGroup?.controls?.[x?.options?.name],
          showLabelInViewMode: true,
        },
      } as IDynamicComponent;
    });
  }
  onCancel() {
    this.ref.close(null);
  }
}
