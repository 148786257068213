import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenseHandlerService } from '@core/services/license-handler/license-handler.service';
import { LocaleService } from '@core/services/locale/locale.service';
import { toSearchFormat } from '@shared/classes/helpers/search-query-formatter';
import { AppDialogService, PathResolverService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { AppInjector } from 'app/app-injector';
import {
  DataTypeEnum,
  IAction,
  IColumn,
  IExportData,
  ITableLoadEvent,
  ITablePageable,
  ModuleKeywords,
  OfflineConfig,
  PermissionActions,
  getPermissionValidItems,
  getProp,
  isNullObj,
} from 'app/shared/classes';
import { LoaderService } from 'app/shared/services/loader.service';
import { ListboxChangeEvent } from 'primeng/listbox';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Subject, debounceTime } from 'rxjs';
import { CustomPagePopupComponent } from './custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss', '../../../../assets/demo/badges.scss'],
})
export class tableComponent implements OnInit, OnDestroy, AfterViewInit {
  emptyItemArray = Array.from({ length: 5 }).map((_, i) => `Item #${i}`);
  @Input() skeletonLoader: boolean = true;
  @ViewChild('dt1') table: Table;

  @ViewChild('globalFilter') globalFilter: ElementRef;
  exportDataDialog: boolean;
  exportPackageDialog: boolean;
  exportSelectedItemsDialog: boolean;
  private _cols: IColumn[] = [];
  private colsByKey: { [key: string]: IColumn } = {};
  public dropdownCols: { name: string; value: IColumn }[] = [];
  private originalNonValidatedCols = [];
  public get cols() {
    return this._cols;
  }
  viewModeOptions = [
    { label: '', value: 'normal', icon: 'pi pi-list', tooltip: 'Normal List' },
    { label: '', value: 'deleted', icon: 'pi pi-trash', tooltip: 'Deleted List' },
  ];
  @Input() set cols(cols: IColumn[]) {
    this.originalNonValidatedCols = cols;
    getPermissionValidItems<IColumn>(cols).then((items) => {
      this._cols = items;
      this.colsByKey = {};
      this.cols.forEach((col) => {
        this.colsByKey[col.key] = col;
      });
      this.getDropdownCols();
    });
  }
  @Input() tableActions: IAction[] = [];
  @Input() selectionMode: 'single' | 'multiple' | undefined | null = 'multiple';
  @Input() actionCol: IColumn = {
    name: 'general.actions.actions',
    key: 'actions',
    dataType: null,
    frozen: true,
    alignFrozen: 'right',
  };
  @Input() inputData: any[] = [];
  @Input() pageInfo: ITablePageable = new ITablePageable();
  @Input() showUpdateRecordsButton: boolean = false;
  @Input() showExportDataButton: boolean = true;
  @Input() showExportPackageButton: boolean = true;
  @Input() showFixRelationsButton: boolean = true;
  @Input() showBulkOperationButton: boolean = true;
  @Input() headerBackground: boolean = true;
  showDeleteAction: boolean = true;
  showEditAction: boolean = true;
  showLockAction: boolean = true;
  showActivateAction: boolean = true;
  showDeactivateAction: boolean = true;
  showStatusActions: boolean = true;
  showSettingsLink: boolean = true;
  showFileExportAction: boolean = true;
  showPackageExportAction: boolean = true;
  showBulkOperationAction: boolean = true;
  mappingContainsBulkField: boolean = false;
  permaDeleteMode: boolean = false;
  swapPageValue: string = 'normal';
  @Input() offlineConfig: OfflineConfig = new OfflineConfig();
  @Input() showSearchField: boolean = true;
  @Input() showTagsSearch: boolean = true;
  @Input() showControlActions: boolean = true;
  @Input() passIndexWithRowDataToAction: boolean = false;
  @Input() useAutoUpdate: boolean = false;
  @Input() rowsPerPageOptions: any[] = [5, 10, 25, 50, 100, 200];
  @Input() settingsUrl: string = null;
  @Input() showSwapPageMode: boolean = false;
  @Input() bulkOperationUrl: string = null;
  @Input() tableActionsStyle: string = null;
  @Input() moduleKeyword: string = null;
  @Input() useTableToSearchFilterMappingV2: boolean = true;
  @Input() loadedFirstTime: boolean = false;
  @Input() dataKey: string = 'code';
  @Input() resizableColumns: boolean = true;
  @Input() showSelectAll: boolean = true;

  @Input() defaultSort: string[] = ['creationDate,desc'];

  @Output() filtersChanged: EventEmitter<ITableLoadEvent> = new EventEmitter();

  @Output() OfflineChanged: EventEmitter<any> = new EventEmitter();

  @Output() onExportData: EventEmitter<IExportData> = new EventEmitter();
  @Output() onExportPackage: EventEmitter<IExportData> = new EventEmitter();

  @Output() onOpenEditDialog: EventEmitter<any> = new EventEmitter();
  @Output() onSwapPageMode: EventEmitter<any> = new EventEmitter();
  @Output() onFixRelationAction: EventEmitter<any> = new EventEmitter();
  @Output() onRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() onClearSelectionAndFilters: EventEmitter<any> = new EventEmitter();
  @Input() set initSelectedItems(value) {
    this.selectedItems = value;
  }

  displayedColumnsModel: any = [];
  displayedColumns: FormControl = new FormControl(null);

  private _pageActions: IAction[] = [];
  get pageActions() {
    return this._pageActions;
  }
  @Input() set pageActions(pageActions: IAction[]) {
    setTimeout(() => {
      this._pageActions = [
        ...pageActions,
        {
          id: 99,
          label: 'Update Records',
          icon: 'pi pi-file-edit',
          iconPos: 'left',
          command: this.openEditDialog.bind(this),
          color: 'primary',
          displayCommand: (data) => this.showUpdateRecordsButton,
        },
        {
          id: 100,
          label: 'Export Data',
          icon: 'pi pi-file-edit',
          iconPos: 'left',
          command: this.openExportDialog.bind(this),
          color: 'primary',
          displayCommand: (data) => this.showExportDataButton,
        },
        {
          id: 101,
          label: 'Export as Package',
          icon: 'fas fa-boxes-packing',
          iconPos: 'left',
          command: this.openExportPackageDialog.bind(this),
          color: 'primary',
          displayCommand: (data) => this.showExportPackageButton,
        },
        {
          id: 102,
          label: 'Revise Relations',
          icon: 'pi pi-wrench',
          iconPos: 'left',
          permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
          command: this.onFixRelations.bind(this),
          color: 'primary',
          displayCommand: (data) => this.showFixRelationsButton,
        },
        {
          id: 103,
          label: 'Bulk Operation',
          icon: 'pi pi-list-check',
          iconPos: 'left',
          command: this.bulkOperation.bind(this),
          color: 'primary',
          displayCommand: (data) =>
            this.mappingContainsBulkField &&
            this.showBulkOperationButton &&
            (this.selectedItems?.length ? true : false),
        },
        // { //this didnt take effect during runtime
        // id: 101,
        // label: 'Export Selected',
        // icon: 'pi pi-file-edit',
        // iconPos: 'left',
        // command: this.openExportSelectedDialog.bind(this),
        // buttonStyle: 'outlined',
        // color: 'secondary',
        // displayCommand:(data) => this.selectedItems.length > 0
        // },
      ];
    }, 10);
  }
  @Input() pageActionsCommandData: any = null;
  @Input() listType: 'split' | 'list' | 'set' | 'speed' | 'dropdown' = 'split';
  @Input() tableActionsListType: 'split' | 'list' | 'set' | 'speed' | 'dropdown' = 'list';
  @Input() sessionStorageKey: string = 'table-state-session';
  @Input() isLoading: boolean = false;
  @Input() lazyLoadOnInit: boolean = true;
  initTableFilters = null;
  private initialAssignedFilters = null;
  @Input() set assignFiltersToTable(filters: any) {
    this.initialAssignedFilters = filters;
    if (this.table && filters) {
      this.table.filters = {
        ...filters,
      };
    }
    // else if(filters){
    //   setTimeout(() => {
    //     if (this.table && filters) {
    //       this.table.filters = {
    //         ...filters,
    //       };
    //     }
    //   }, 100);
    // }
  }
  @ContentChild('customActionTemplate') customActionTemplate: TemplateRef<any>;
  private loadDataSubject = new Subject<{ event: TableLazyLoadEvent; showLoader: boolean }>();
  public get globalFilters() {
    return this.cols.filter((x) => x.filter?.type == 'text').map((x) => x.key);
  }
  selectedItems: any[] = [];
  private lastTableEvent: TableLazyLoadEvent = null;
  reorderableColumns: boolean = false;
  autoUpdateInterval: any = null;
  settingsButtonPermission;
  private _groupingMode: 'rowspan' | 'subheader' | 'expand' = null;
  get groupingMode() {
    return this._groupingMode;
  }
  set groupingMode(groupingMode: 'rowspan' | 'subheader' | 'expand') {
    this._groupingMode = groupingMode;
    this.rowGroupMode = groupingMode == 'expand' ? 'subheader' : groupingMode;
    if (isNullObj(groupingMode)) {
      this.sortField = null;
      this.groupRowsBy = null;
    }
  }

  @Input() set manualGroup(event: { mode: 'rowspan' | 'subheader' | 'expand'; column: IColumn }) {
    if (event) {

      this.tryManualGroupping(event);
    }
  }
  tryManualGroupping(event) {
    if (this.isLoading) {
      setTimeout(() => {
        this.tryManualGroupping(event)
      });
    } else {
      this.selectRowGroupingMode(event);

    }
  }
  groupRowsBy: string = null;
  rowGroupMode: 'rowspan' | 'subheader' = null;
  sortField: string = null;
  groupColumn: IColumn = null;
  groupingActions: IAction[] = [
    {
      id: 1,
      label: 'Header Row',
      command: this.selectRowGroupingMode.bind(this),
      color: 'primary',
      displayCommand: (data) => this.showUpdateRecordsButton,
    },
  ];

  tagsCodesControl = new FormControl();
  tagsControl = new FormControl();
  tagsFilterControl = new FormControl();
  tagsControlsObj;
  currentPageReportTemplate = 'Showing {first} to {last} of {totalRecords} entries';
  constructor(
    public loaderService: LoaderService,
    private exportService: ExportDataService,
    public licenseService: LicenseHandlerService,
    public bulkOperationService: BulkOperationService,
    private router: Router,
    private route: ActivatedRoute,
    private appDialogService: AppDialogService,
    public localeService: LocaleService
  ) {
    this.loadDataSubject.pipe(debounceTime(100)).subscribe(({ event, showLoader }) => {
      this.loadDataInternal(event, showLoader);
    });
    // this.tagsCodesControl.valueChanges.subscribe(val => {
    //   this.setNewTagsFilter(val);
    // });
    this.tagsControlsObj = {
      tagStringsControl: this.tagsControl,
      tagCodesControl: this.tagsCodesControl,
      emitEvent: true,
    };

    this.tagsControl.valueChanges.subscribe((val) => {
      this.setNewTagsFilter(val);
    });
  }
  ngAfterViewInit(): void {
    if (!isNullObj(this.moduleKeyword)) {
      const pathResolverService = AppInjector.get(PathResolverService);
      pathResolverService
        .getMappingServiceByModuleKeyword(this.moduleKeyword as ModuleKeywords)
        .recordFields?.forEach((element) => {
          if (element?.useInBulk) {
            this.mappingContainsBulkField = true;
          }
        });
    }
    // this.table.filters['recordStatus'] =  [{matchMode: 'in', operator: 'and', value: ['ACTIVE']}];
    if (this.router.url.indexOf('/list') != -1) {
      this.route?.queryParams?.subscribe({
        next: (queryParams) => {
          this.handleFiltersFromQueryParams(queryParams);
        },
      });
    }
    if (this.initialAssignedFilters) {
      if (this.table && this.initialAssignedFilters) {
        this.table.filters = {
          ...this.initialAssignedFilters,
        };
        this.initialAssignedFilters = null;
      }
    }
    this.initTableFilters = { ...this.table.filters };
  }
  ngOnDestroy(): void {
    this.selectedItems = [];
    delete this.selectedItems;
    if (this.autoUpdateInterval) {
      clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = null;
    }
  }
  parseQueryParamsFilters(queryParams) {
    const lazyEvent: TableLazyLoadEvent = {};
    let parsedFilters = {};
    try {
      parsedFilters = queryParams?.['filters'] ? JSON.parse(atob(decodeURIComponent(queryParams?.['filters']))) : {};
    } catch (error) {
      console.error('PARSING FILTERS FAILED');
    }
    lazyEvent.filters = parsedFilters;
    lazyEvent.first =
      queryParams?.['page'] && queryParams?.['size']
        ? parseInt(queryParams?.['page']) * parseInt(queryParams?.['size'])
        : this.lastTableEvent?.first || 0;
    lazyEvent.rows = queryParams?.['size'] ? parseInt(queryParams?.['size']) : this.lastTableEvent?.rows || 10;
    lazyEvent.multiSortMeta = queryParams?.['sort']
      ? (JSON.parse(decodeURIComponent(queryParams?.['sort'])) as string[]).map((x) => {
        const [field, order] = x.split(',');
        return { field, order: order == 'desc' ? 0 : 1 };
      })
      : null;
    //  (event?.multiSortMeta?.map(x => x.field + ',' + (x.order > 0 ? 'asc' : 'desc'))) : [this.sortField + ',asc'];
    return lazyEvent;
  }
  assignLazyEventToTable(lazyEvent) {
    this.table.filters = {
      ...this.lastTableEvent?.filters,
      ...lazyEvent.filters,
    };
    this.table.first = lazyEvent.first;
    this.table.rows = lazyEvent.rows;
    this.table.multiSortMeta = lazyEvent.multiSortMeta;
    this.setTags(this.table?.filters?.tags?.[0]?.value);
  }
  compareTableEvents(event: TableLazyLoadEvent, lazyEvent: TableLazyLoadEvent) {
    if (isNullObj(event)) {
      return !isNullObj(lazyEvent);
    }
    if (
      event.first != lazyEvent.first ||
      event.rows != lazyEvent.rows ||
      Object.keys(lazyEvent.filters).length > 0 ||
      lazyEvent.multiSortMeta != null
    ) {
      let differentFilters = false;
      let differentSort = false;
      if (Object.entries(event.filters).length == 0) {
        differentFilters = true;
      } else {
        Object.entries(lazyEvent.filters).forEach(([key, value]) => {
          let keyFound = false;
          Object.entries(event.filters).forEach(([lKey, lValue]) => {
            if (key == lKey) {
              keyFound = true;
              if (JSON.stringify(value) != JSON.stringify(lValue)) {
                differentFilters = true;
              }
            }
          });
          if (!keyFound) {
            differentFilters = true;
          }
        });
      }
      if (
        !isNullObj(event.multiSortMeta) &&
        !isNullObj(lazyEvent.multiSortMeta) &&
        JSON.stringify(event.multiSortMeta) != JSON.stringify(lazyEvent.multiSortMeta)
      ) {
        differentSort = true;
      }
      if (differentSort || differentFilters) {
        return true;
      }
    }
    return false;
  }
  handleFiltersFromQueryParams(queryParams) {
    const lazyEvent: TableLazyLoadEvent = this.parseQueryParamsFilters(queryParams);

    this.assignLazyEventToTable(lazyEvent);
    if (this.compareTableEvents(this.lastTableEvent, lazyEvent)) {
      this.lazyLoadOnInit = false;
      if (!this.rowsPerPageOptions.includes(lazyEvent.rows)) {
        this.rowsPerPageOptions = [...this.rowsPerPageOptions, lazyEvent.rows];
        this.rowsPerPageOptions.sort((a, b) => a - b);
      }
      this.loadData(lazyEvent, false);
    }
  }
  ngOnInit(): void {
    if (!this.offlineConfig.lazy) {
      this.OfflineChanged.emit();
    } else if (this.useAutoUpdate) {
      this.autoRefreshTable();
    }
    this.settingsButtonPermission = `${PermissionActions.Delete}${this.moduleKeyword}`;
    this.setCurrentPageReportTemplate();
    this.localeService.languageState.subscribe((lang) => {
      this.setCurrentPageReportTemplate();
    });
  }
  setCurrentPageReportTemplate() {
    const showingKey = this.localeService.translate('general.table.pagination.showing');
    const toKey = this.localeService.translate('general.table.pagination.to');
    const ofKey = this.localeService.translate('general.table.pagination.of');
    const entriesKey = this.localeService.translate('general.table.pagination.entries');
    this.currentPageReportTemplate = `${showingKey} {first} ${toKey} {last} ${ofKey} {totalRecords} ${entriesKey}`;
  }
  clear(table: Table) {
    sessionStorage.removeItem(this.sessionStorageKey);
    this.cols = [...this.cols];
    table.clear();
    this.globalFilter.nativeElement.value = '';
    this.selectedItems = [];
    this.selectRowGroupingMode(null);
    if (this.onClearSelectionAndFilters) {
      this.onClearSelectionAndFilters?.emit(this);
    }
  }
  autoRefreshTable() {
    if (this.autoUpdateInterval) {
      clearInterval(this.autoUpdateInterval);
      this.autoUpdateInterval = null;
    }
    if (this.offlineConfig.lazy && this.useAutoUpdate) {
      this.autoRefreshRequest = this.autoRefreshRequest.bind(this);
      this.autoUpdateInterval = setInterval(this.autoRefreshRequest, 15000);
    }
  }
  autoRefreshRequest() {
    if (this.useAutoUpdate) {
      this.reload(false);
    }
  }
  reload(showLoader: boolean = true) {
    if (this.isLoading == false) {
      this.loadData(this.lastTableEvent, showLoader);
    }
  }
  loadData(event: TableLazyLoadEvent, showLoader: boolean = true) {
    this.loadDataSubject.next({ event, showLoader });
  }

  private loadDataInternal(event: TableLazyLoadEvent, showLoader: boolean = true) {
    if (event) {
      this.lastTableEvent = event;

      // let query = toMongoDBFormat(event.filters as any,[...this.globalFilters]);
      let searchObj = toSearchFormat(
        event?.filters as any,
        [...this.globalFilters],
        this.colsByKey,
        this.useTableToSearchFilterMappingV2
      );
      let filters = searchObj ? (Object.keys(searchObj).length > 0 ? [searchObj] : []) : [];

      this.setPagination(event);
      const filterObjTypes = this.cols
        .map((x) => ({
          key: x.key + (x?.filter?.extraKey ? '.' + x?.filter?.extraKey : ''),
          value: { type: x?.filter?.type, extraKey: x?.filter?.extraKey },
        }))
        .reduce((obj, { key, value }) => {
          obj[key] = value;
          return obj;
        }, {});
      this.filtersChanged.emit({
        // query:query,
        filters: filters,
        // ? filters.map((x) => {
        //   if (filterObjTypes[x.property]?.type == 'userAndGroupSelector') {
        //     return {
        //       ...x,
        //       value: x.value
        //         ? Array.isArray(x.value)
        //           ? x.value.map((x) => x.name ?? x.user)
        //           : (x.value?.name ?? x.value?.user)
        //         : null,
        //     };
        //   }
        //   return x;
        // })
        // : filters,
        pageInfo: this.pageInfo,
        tableOriginalFilters: event?.filters as any,
        showLoader: this.loadedFirstTime ? showLoader : true,
        textSearch: this.textSearchObj,
      });
    } else {
      this.filtersChanged.emit({
        // query:query,
        filters: [],
        pageInfo: this.pageInfo,
        tableOriginalFilters: event?.filters as any,
        showLoader: this.loadedFirstTime ? showLoader : true,
        textSearch: this.textSearchObj,
      });
    }
    this.loadedFirstTime = true;
  }

  setPagination(event: TableLazyLoadEvent) {
    this.pageInfo.pagination.page = event.first / event.rows;

    const sortArray: string[] = !this.sortField
      ? event?.multiSortMeta?.map((x) => x.field + ',' + (x.order > 0 ? 'asc' : 'desc'))
      : [this.sortField + ',asc'];
    // this.pageInfo.pagination.sort = !!event.sortField ? [((event.sortField as string).split('.')[0]+','+(event.sortOrder > 0 ? 'asc' : 'desc'))] : this.pageInfo.pagination.sort;
    this.pageInfo.pagination.sort = isNullObj(sortArray) ? this.defaultSort : sortArray;
    this.pageInfo.pagination.size = event.rows;
  }

  getElementValue(key: string, data: any) {
    let value = data[key];
    const defaultValue = getProp(data, key);
    value = defaultValue;
    if (
      this.localeService?.language?.langCode &&
      data?.originalLanguage &&
      data?.originalLanguage != this.localeService?.language?.langCode
    ) {
      const translatedValue = getProp(data, `translations.${this.localeService?.language?.langCode}.${key}`);
      value = isNullObj(translatedValue) ? defaultValue : translatedValue;
    }
    return value;
  }
  getDropdownCols() {
    this.displayedColumnsModel = [];
    this.dropdownCols = this.cols.map((x) => {
      this.displayedColumnsModel.push({ ...x });
      return {
        name: x.name,
        value: { ...x },
      };
    });
  }
  exp(e) {
    this.onExportData.emit({
      ...e,
      cols: this.displayedColumnsModel?.length > 0 ? [...this.displayedColumnsModel] : [...this.cols],
    });
  }
  expPackage(e) {
    this.onExportPackage.emit({
      ...e,
      cols: this.displayedColumnsModel?.length > 0 ? [...this.displayedColumnsModel] : [...this.cols],
      selectedItems: this.selectedItems,
    });
  }
  bulkOperation() {
    if (this.bulkOperationUrl) {
      this.bulkOperationService.setupStepper(this.selectedItems, this.router.url, this.permaDeleteMode);
      this.router.navigate([this.bulkOperationUrl]);
    }
  }
  onSelectionChange(event: ListboxChangeEvent) {
    let newSelection = [];
    this.cols.map((x) => {
      if ((event.value as IColumn[]).findIndex((val) => val.key === x.key) != -1) {
        newSelection.push({ ...x });
      }
    });
    this.displayedColumnsModel = newSelection;
  }
  onRowSelect(event) {
    this._pageActions = [...this._pageActions];
  }

  onRowUnselect(event) {
    this._pageActions = [...this._pageActions];
  }

  onRowSelectionChange(event) {
    this._pageActions = [...this._pageActions];

    this.onRowSelection.emit(this.selectedItems);
  }

  exportSelectedItems(e) {
    this.exportService.parsedExportData(
      e.type,
      this.displayedColumnsModel?.length > 0 ? [...this.displayedColumnsModel] : [...this.cols],
      this.selectedItems,
      ''
    );
  }
  openEditDialog(event) {
    this.onOpenEditDialog.emit(this.selectedItems);
  }
  openExportDialog() {
    // this.onOpenEditDialog.emit(this.selectedItems);
    if (!!!this.selectedItems.length) {
      this.exportDataDialog = true;
    } else {
      this.exportSelectedItemsDialog = true;
    }
  }
  openExportPackageDialog() {
    this.exportPackageDialog = true;
    // if(!!!this.selectedItems.length){
    //     this.exportDataDialog = true;
    // }else{
    //     this.exportSelectedItemsDialog = true;
    // }
  }
  onFixRelations() {
    this.onFixRelationAction.emit(this.selectedItems);
    // if(!!!this.selectedItems.length){
    // }else{

    // }
  }

  // onPage(event: any) {
  //   if (event.rows === 'custom') {
  //     const customRows = prompt('Enter the number of rows per page:');
  //     const customRowsNumber = parseInt(customRows, 10);

  //     if (!isNaN(customRowsNumber) && customRowsNumber > 0) {
  //       // this.rows = customRowsNumber;
  //     }
  //   } else {
  //     // this.rows = event.rows;
  //   }
  // }
  // openExportSelectedDialog(){
  //     this.onOpenEditDialog.emit(this.selectedItems);
  // }
  customRows() {
    this.appDialogService.showDialog(CustomPagePopupComponent, 'Custom Rows', (data) => {
      if (data) {
        const customRows = data;
        if (customRows) {
          this.pageInfo.pagination.size = customRows; // Update rows with custom value
          let searchObj = toSearchFormat(
            this.lastTableEvent.filters as any,
            [...this.globalFilters],
            this.colsByKey,
            this.useTableToSearchFilterMappingV2
          );
          this.rowsPerPageOptions = [...this.rowsPerPageOptions, this.pageInfo.pagination.size];
          this.rowsPerPageOptions.sort((a, b) => a - b);
          this.table.rows = this.pageInfo.pagination.size;
          this.lastTableEvent.rows = this.pageInfo.pagination.size;
          let filters = searchObj ? (Object.keys(searchObj).length > 0 ? [searchObj] : []) : [];
          this.filtersChanged.emit({
            // query:query,
            filters: filters,
            pageInfo: this.pageInfo,
            tableOriginalFilters: this.lastTableEvent.filters as any,
            showLoader: true,
            textSearch: this.textSearchObj,
          });
        }
      }
    });
  }
  toggleReorder(state) {
    this.reorderableColumns = state;
  }
  onColReorder(event: { dragIndex?: number; dropIndex?: number; columns?: any[] }) {
    this.displayedColumnsModel = [...event.columns];
  }

  getOptions(options, dataType): any {
    return {
      ...options,
      filter: this.globalSearchText,
      useStaticMaxWidth: dataType == DataTypeEnum.Text ? false : undefined,
    };
  }
  selectRowGroupingMode(event: { mode: 'rowspan' | 'subheader' | 'expand'; column: IColumn }, reload: boolean = true) {
    this.groupingMode = event?.mode ?? null;
    this.groupRowsBy = event?.column.key ?? null;
    this.sortField = event?.column.key ?? null;
    this.groupColumn = event?.column ?? null;
    if (reload) {
      this.reload(true);
    }
  }
  swapPageMode() {
    this.permaDeleteMode = !this.permaDeleteMode;
    this.onSwapPageMode.emit('swap');
  }
  get globalSearchText() {
    return this.globalFilter?.nativeElement?.value;
  }
  get textSearchObj() {
    return isNullObj(this.globalSearchText)
      ? null
      : {
        search: this.globalSearchText,
        language: 'en',
        caseSensitive: false,
        diacriticSensitive: false,
      };
  }

  setNewTagsFilter(tags) {
    const newEvent = {
      ...this.lastTableEvent,
      filters: {
        ...this.cols
          .filter((x) => x?.filter?.matchMode)
          .map((x) => {
            return { value: null, matchMode: x.filter.matchMode, operator: 'and' };
          }),
        ...this.initTableFilters,
        ...this.table.filters,
        tags: tags?.length
          ? [
            {
              value: tags,
              matchMode: 'CONTAINS_ALL',
              operator: 'and',
            },
          ]
          : {
            value: [],
            matchMode: 'CONTAINS_ALL',
            operator: 'and',
          },
      },
    };
    if (!tags?.length && newEvent?.filters?.tags) {
      delete newEvent?.filters?.tags;
    }
    this.table.filters = { ...newEvent.filters };
    this.table._filter();
    if (!tags?.length) this.tagsFilterControl.patchValue([]);
    // this.loadData({ ...newEvent });
  }

  setTags(tags) {
    this.tagsControl.patchValue(tags ?? [], { emitEvent: false });
    this.tagsCodesControl.patchValue(
      (tags ?? [])?.map((x, i) => x + i),
      { emitEvent: false }
    );
    this.tagsControlsObj = {
      tagStringsControl: this.tagsControl,
      tagCodesControl: this.tagsCodesControl,
      emitEvent: false,
    };
  }

  onFilterChange(event) {
    if (this.showTagsSearch) {
      this.setTags(event.filters.tags?.[0]?.value);
    }
  }
}
