import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';

@Component({
  selector: 'app-record-code-filter',
  templateUrl: './record-code-filter.component.html',
  styleUrls: ['./record-code-filter.component.scss'],
})
export class RecordCodeFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }

  initInput() {
    this.dropDownInput = {
      componentType: TargetCodeSelectorComponent,
      options: {
        label: '',
        name: '',
        control: new FormControl(null),
        inputOptions: {
          codeSelectorInput: {
            targetTypes: this.col.filter.multi ? (this.col.filter.recordCodeType as any[]) : ([this.col.filter.recordCodeType] as any[]),
            extraFilters: this.col?.filter?.options?.extraFilters,
            showFilters: false,
            appendTo: null
            // inlineMode:true,
          },
          dropDownInput: {
            optionLabel: 'label',
            optionValue: this.col?.filter?.options?.optionValue || 'code',
            multi: true,
            items: [],
          },
        },
      },
    };
  }
}
