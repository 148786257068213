import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  Base,
  IAction,
  IViewMode,
  ModuleKeywordRootPath,
  PermissionActions,
  TargetTypeEnum,
  getModuleKeywordByCode,
  isNullObj,
  routeToLocaleCase,
  toCamelCase
} from '@shared/classes';
import { PathResolverService } from '@shared/services/path-resolver.service';
import { RelationsService } from '@shared/services/relation-service/relations.service';
import { ToastService } from '@shared/services/toast.service';
import { MenuItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.scss'],
})
export class RelationsComponent extends Base implements OnInit {
  subject: Subject<Boolean> = new Subject<Boolean>();
  refreshDataSubject: Subject<Boolean> = new Subject<Boolean>();

  submitButtonAction: IAction = {
    id: 1,
    label: 'Add Relation',
    buttonType: 'button',
    iconPos: 'left',
    color: 'primary',
    command: this.fire.bind(this),
    icon: 'pi pi-plus',
  };
  chartButtonAction: IAction = {
    id: 2,
    label: 'Relations Chart',
    buttonType: 'button',
    iconPos: 'left',
    color: 'primary',
    command: this.goToRelationChart.bind(this),
    icon: 'pi pi-link',
  };
  fixRelationsButtonAction: IAction = {
    id: 3,
    label: 'Revise Relations',
    buttonType: 'button',
    iconPos: 'left',
    color: 'primary',
    command: this.fixRelations.bind(this),
    icon: 'pi pi-wrench',
  };
  refreshButtonAction: IAction = {
    id: 4,
    label: '',
    buttonType: 'button',
    iconPos: 'left',
    color: 'secondary',
    command: this.refreshData.bind(this),
    icon: 'pi pi-refresh',
    buttonStyle: 'raised',
  };
  niceModeButtonAction: IAction = {
    id: 5,
    label: '',
    buttonType: 'button',
    iconPos: 'left',
    color: 'secondary',
    command: this.niceModeSwitch.bind(this),
    icon: 'pi pi-star',
    buttonStyle: 'raised',
  };

  @Input() mode: IViewMode = IViewMode.edit;

  items: MenuItem[] = [];
  adminPermission: string;
  private _sourceCode: string;
  get sourceCode(): string {
    return this._sourceCode;
  }
  @Input() set sourceCode(value: string) {
    this._sourceCode = value;
    this.adminPermission = `${PermissionActions.Delete}${getModuleKeywordByCode(value)}`;
    // this.loadData();
  }

  @Input() sourceUUID: string;
  @Input()
  sourceType: TargetTypeEnum;

  @Input()
  fromType: TargetTypeEnum;

  tabOption = 0;

  typesMap;

  niceMode = false;

  list: any[] = [];
  @Input() set reloadData(reload: boolean) {
    if (reload) {
      this.refreshData();
    }
  }
  constructor(
    private relationsService: RelationsService,
    private router: Router,
    private pathService: PathResolverService,
    private toastService: ToastService,
    public localeService: LocaleService
  ) {
    super();
  }

  ngOnInit() {
    // this.loadData()
  }

  fire() {
    this.subject.next(true);
  }
  refreshData() {
    this.refreshDataSubject.next(true);
  }
  goToRelationChart() {
    this.router.navigateByUrl('entity/network-chart/' + this.sourceCode);
  }

  addedNew(e) {
    // this.loadData()
  }
  onLoadData(data: any[]) {
    if (!isNullObj(data)) {
      this.list = [...new Set(data?.filter(el => ModuleKeywordRootPath[el.targetType])?.map((el) => el.targetType))];
      let pList = this.list.map((el) => {
        return {
          label: this.localeService.translate(
            `modules.${routeToLocaleCase(ModuleKeywordRootPath[el])}.${toCamelCase(el)}`
          ),
          command: this.changeFromType.bind(this),
        };
      });

      pList.unshift({
        label: this.localeService.translate('general.actions.All'),
        command: this.changeFromType.bind(this),
      });

      this.items = [...pList];
    } else {
      this.clearData();
    }
  }
  loadData() {
    if (this.sourceCode) {
      this.relationsService
        .getBySourceCode(this.sourceCode)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: any) => {
            this.list = [...new Set(res?.data?.filter(el => ModuleKeywordRootPath[el.targetType])?.map((el) => el.targetType))];
            let pList = this.list.map((el) => {
              return {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[el])}.${toCamelCase(el)}`
                ),
                command: this.changeFromType.bind(this),
              };
            });

            pList.unshift({
              label: this.localeService.translate('general.actions.All'),
              command: this.changeFromType.bind(this),
            });

            this.items = [...pList];
          },
          error: (error) => { },
          complete: () => { },
        });
    } else {
      this.clearData();
    }
  }
  fixRelations() {
    const service = this.pathService
      .getDataServiceByCode(this.sourceCode)
      .fixRelations({
        filters: [
          {
            property: 'code',
            operation: 'EQUAL',
            value: this.sourceCode,
            typeShape: 'NORMAL',
          },
        ],
      })
      .subscribe({
        next: (res) => {
          this.toastService.success('Success', 'Relations calculating');
          this.refreshData();
        },
        error: (err) => {
          this.toastService.success('Error', 'Task failed try again!');
        },
      });
  }
  changeFromType(event) {
    this.fromType = event.item.label == 'All' ? null : event.item.label.toUpperCase().replace(/ /g, '_');
  }
  clearData() {
    this.list = [];
    this.items = [];
  }

  niceModeSwitch() {
    this.niceMode = !this.niceMode;
    this.niceModeButtonAction = {
      id: 3,
      label: '',
      buttonType: 'button',
      iconPos: 'left',
      color: this.niceMode ? 'primary' : 'secondary',
      command: this.niceModeSwitch.bind(this),
      icon: 'pi pi-star',
      buttonStyle: 'raised',
    };
  }
}
