<p-columnFilter
  [field]="col?.filter?.extraKey ? col.key + '.' + col?.filter?.extraKey : col.key"
  [display]="col.filter.display || 'menu'"
  [placeholder]="col.filter.placeholder || 'Search By ' + col.name"
  [matchMode]="col.filter.matchMode"
  [showMatchModes]="false"
  [showOperator]="false"
  [showAddButton]="false"
  [showClearButton]="false"
  [showApplyButton]="false"
>
  <ng-template pTemplate="header">
    <div class="px-3 pt-3 pb-0">
      <span class="font-bold">{{ col?.filter?.placeholder || 'Search By ' + col?.name }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <div id="p_color_overlay">
      <ng-template
        appDynamicComponent
        mode="edit"
        [manualValue]="value"
        [dynamicComponentInfo]="dropDownInput"
      ></ng-template>
    </div>
    <div class="flex justify-content-between align-items-center mt-4">
      @if (col.filter.showClearButton ?? true) {
        <p-button
          label="Clear"
          styleClass="p-button-outlined"
          (onClick)="filter(undefined); dropDownInput.options.control.patchValue(null)"
        ></p-button>
      }
      @if (col.filter.showApplyButton ?? true) {
        <p-button label="Apply" (onClick)="filter(dropDownInput.options.control.value)"></p-button>
      }
    </div>
  </ng-template>
</p-columnFilter>
