<div class="w-full h-full">
  @if (groupingMode != 'expand') {
    <p-table
      #dt1
      [value]="skeletonLoader && isLoading ? emptyItemArray : inputData"
      [dataKey]="dataKey"
      [loading]="skeletonLoader ? false : isLoading"
      [rowHover]="true"
      [paginatorDropdownAppendTo]="'body'"
      styleClass=""
      [globalFilterFields]="globalFilters"
      responsiveLayout="scroll"
      [breakpoint]="'960px'"
      [scrollable]="true"
      [paginator]="offlineConfig.paginator"
      [rows]="pageInfo.pagination.size"
      [totalRecords]="pageInfo.totalElements"
      [lazy]="offlineConfig.lazy"
      (onLazyLoad)="loadData($event)"
      [lazyLoadOnInit]="lazyLoadOnInit"
      [showCurrentPageReport]="true"
      [filterDelay]="600"
      [currentPageReportTemplate]="currentPageReportTemplate"
      [rowsPerPageOptions]="rowsPerPageOptions"
      [selectionMode]="selectionMode"
      [(selection)]="selectedItems"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      (selectionChange)="onRowSelectionChange($event)"
      (onFilter)="onFilterChange($event)"
      stateStorage="session"
      [stateKey]="true ? undefined : sessionStorageKey"
      [reorderableColumns]="reorderableColumns"
      (onColReorder)="onColReorder($event)"
      [columns]="cols"
      [resizableColumns]="resizableColumns"
      [class.disable-column-resize]="!resizableColumns"
      columnResizeMode="expand"
      [styleClass]="
        'justify-content-start flex flex-column h-full ' + (groupingMode == 'rowspan' ? ' p-datatable-lines ' : '')
      "
      sortMode="multiple"
      [groupRowsBy]="groupRowsBy"
      [rowGroupMode]="rowGroupMode"
    >
      <ng-template pTemplate="groupheader" let-data let-rowIndex="rowIndex" let-expanded="expanded">
        <tr>
          <td [attr.colspan]="displayedColumnsModel?.length > 0 ? displayedColumnsModel?.length : cols?.length">
            <div class="flex flex-row gap-2">
              <app-view-switch
                [viewType]="groupColumn?.dataType"
                [data]="groupColumn?.passRowAsData ? data : getElementValue(groupColumn?.key, data)"
                [options]="getOptions(groupColumn?.options, null)"
                [translationKeyPrefix]="groupColumn?.translationKeyPrefix"
              >
              </app-view-switch>
            </div>
          </td>
        </tr>
      </ng-template>
      @if (offlineConfig.showActionBar) {
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row gap-2">
            @if (showControlActions) {
              <div class="flex flex-row gap-2 flex-wrap mb-2">
                <ng-content select="[left-header]"></ng-content>
                @if (pageActions.length > 0) {
                  <div>
                    @if (pageActions.length > 0) {
                      <app-button-list
                        class="align-self-center"
                        [listType]="listType"
                        [actionList]="pageActions"
                        [commandData]="pageActionsCommandData"
                        [useStaticLeftRightForSplit]="false"
                        [appendTo]="null"
                      ></app-button-list>
                    }
                  </div>
                }
                <!-- <app-button
                pTooltip="Update All Records"
                tooltipPosition="top"
                [commandData]="dt1"
                                  [action]="{
                                      id: 99,
                                      label: 'Update Records',
                                      icon: 'pi pi-file-edit',
                                      iconPos: 'left',
                                      command: openEditDialog.bind(this),
                                      buttonStyle: 'outlined'
                                  }"
              ></app-button> -->
                <ng-container *ngxPermissionsOnly="settingsButtonPermission">
                  <!-- @if (settingsUrl) {
                    <a [routerLink]="settingsUrl">
                      <app-button
                        style="display: block"
                        pTooltip="Edit Module Settings"
                        tooltipPosition="top"
                        [action]="{
                          id: 199,
                          label: '',
                          icon: 'pi pi-cog',
                          iconPos: 'left',
                          buttonStyle: 'raised',
                          color: 'secondary',
                        }"
                      ></app-button>
                    </a>
                  } -->
                  <!-- @if (showSwapPageMode) {
                    <app-button
                      style="display: block"
                      pTooltip="Change Manage Mode"
                      tooltipPosition="top"
                      [action]="{
                        id: 199,
                        label: '',
                        icon: 'pi pi-trash',
                        iconPos: 'left',
                        buttonStyle: 'raised',
                        color: 'secondary',
                        command: swapPageMode.bind(this),
                      }"
                    ></app-button>
                  } -->
                </ng-container>
                <app-button
                  pTooltip="Refresh"
                  tooltipPosition="top"
                  [commandData]="dt1"
                  [action]="{
                    id: 99,
                    label: '',
                    icon: 'pi pi-refresh',
                    iconPos: 'left',
                    command: reload.bind(this),
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <app-button
                  pTooltip="Clear Filters, Selection"
                  tooltipPosition="top"
                  [commandData]="dt1"
                  [action]="{
                    id: 1,
                    label: '',
                    icon: 'pi pi-filter-slash',
                    iconPos: 'left',
                    command: clear.bind(this),
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <app-button
                  pTooltip="Select Table Columns"
                  tooltipPosition="top"
                  (onClickAction)="op.toggle($event)"
                  [action]="{
                    id: 2,
                    label: '',
                    icon: 'pi pi-eye',
                    iconPos: 'left',
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>

                <!-- <app-button
              *ngIf="!!!selectedItems.length"
              (onClickAction)="exportop.toggle($event)"
              pTooltip="Export Table"
              tooltipPosition="top"
                                  [action]="{
                                      id: 1,
                                      label: 'Export',
                                      icon: 'pi pi-file-export',
                                      iconPos: 'left',
                                      buttonStyle: 'outlined',
                                      color:'secondary'
                                  }"
            ></app-button>
            <app-button
              *ngIf="!!selectedItems.length"
              (onClickAction)="exportItemsop.toggle($event)"
              pTooltip="Export Selected Items"
              tooltipPosition="top"
                                  [action]="{
                                      id: 1,
                                      label: 'Export Selected',
                                      icon: 'pi pi-file-export',
                                      iconPos: 'left',
                                      buttonStyle: 'outlined',
                                      color:'secondary'
                                  }"
            ></app-button> -->
                @if (!reorderableColumns) {
                  <app-button
                    (onClickAction)="toggleReorder(true)"
                    pTooltip="Enable Reorder Columns"
                    tooltipPosition="top"
                    [action]="{
                      id: 1,
                      label: '',
                      icon: 'pi pi-arrows-alt',
                      iconPos: 'left',
                      buttonStyle: 'raised',
                      color: 'secondary',
                    }"
                  ></app-button>
                }
                @if (reorderableColumns) {
                  <app-button
                    (onClickAction)="toggleReorder(false)"
                    pTooltip="Disable Reorder Columns"
                    tooltipPosition="top"
                    [action]="{
                      id: 1,
                      label: '',
                      icon: 'pi pi-sort-alt-slash',
                      iconPos: 'left',
                      buttonStyle: 'raised',
                      color: 'secondary',
                    }"
                  ></app-button>
                }
                <app-button
                  pTooltip="Custom Number of Rows"
                  tooltipPosition="top"
                  [commandData]="dt1"
                  [action]="{
                    id: 1,
                    label: '',
                    icon: 'fa fa-list-ol',
                    iconPos: 'left',
                    command: customRows.bind(this),
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <ng-container *ngxPermissionsOnly="settingsButtonPermission">
                  @if (settingsUrl || showSwapPageMode) {
                    <div class="border-left-2 border-500"></div>
                  }
                  @if (settingsUrl) {
                    <a [routerLink]="settingsUrl">
                      <app-button
                        style="display: block"
                        pTooltip="Edit Module Settings"
                        tooltipPosition="top"
                        [action]="{
                          id: 199,
                          label: '',
                          icon: 'pi pi-cog',
                          iconPos: 'left',
                          buttonStyle: 'raised',
                          color: 'secondary',
                        }"
                      ></app-button>
                    </a>
                  }
                  @if (showSwapPageMode) {
                    <!-- Templates inside select button arent working needs more testing -->
                    <p-selectButton
                      [options]="viewModeOptions"
                      [(ngModel)]="swapPageValue"
                      [unselectable]="true"
                      optionLabel="label"
                      optionValue="value"
                      (onChange)="swapPageMode()"
                      styleClass="view-switch-buttons"
                    >
                    </p-selectButton>
                  }
                </ng-container>
                <p-overlayPanel #op [showCloseIcon]="true">
                  <ng-template pTemplate>
                    <p-listbox
                      [options]="dropdownCols"
                      [checkbox]="true"
                      [filter]="true"
                      [multiple]="true"
                      [ngModel]="displayedColumnsModel"
                      (onChange)="onSelectionChange($event)"
                      optionLabel="name"
                      [optionValue]="'value'"
                      [style]="{ width: '15rem' }"
                    >
                    </p-listbox>
                  </ng-template>
                </p-overlayPanel>
                <p-overlayPanel #exportop [showCloseIcon]="true" [styleClass]="'w-14rem'">
                  <ng-template pTemplate>
                    <app-export-data-form
                      (formSubmit)="exp($event)"
                      [totalElements]="pageInfo.totalElements"
                    ></app-export-data-form>
                  </ng-template>
                </p-overlayPanel>
                <p-overlayPanel #exportItemsop [showCloseIcon]="true" [styleClass]="'w-14rem'">
                  <ng-template pTemplate>
                    <app-export-selected-data-form
                      (formSubmit)="exportSelectedItems($event)"
                    ></app-export-selected-data-form>
                  </ng-template>
                </p-overlayPanel>
              </div>
            }
            <ng-content select="[right-header]"></ng-content>
            @if (showSearchField) {
              <div class="flex flex-row gap-2">
                <span class="p-input-icon-left h-fit">
                  <i class="pi pi-search"></i>
                  <input
                    appMaxCharacterValidator
                    pInputText
                    type="text"
                    #globalFilter
                    (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                    [placeholder]="'general.fields.searchKeyword.label' | translate: 'Search Keyword'"
                    class="w-full"
                    name="search-keyword"
                    id="search-keyword"
                  />
                </span>
                @if (showTagsSearch) {
                  <span class="p-input-icon-left h-fit">
                    <app-tag-search-input
                      [viewMode]="'create'"
                      [name]="'tags'"
                      [placeholder]="'general.fields.tags.label' | translate: 'Tags'"
                      [controls]="tagsControlsObj"
                    ></app-tag-search-input>
                  </span>
                }
              </div>
            }
          </div>
        </ng-template>
      }
      <ng-template pTemplate="header" let-columns>
        <tr [style]="headerBackground ? 'background:var(--primary-bg-400);' : ''">
          @if (showSelectAll) {
            @if (selectionMode == 'multiple') {
              <th pFrozenColumn [frozen]="false" [alignFrozen]="'left'" class="w-3rem max-w-3rem">
                <p-tableHeaderCheckbox [inputId]="'table-header-checkbox-1'"></p-tableHeaderCheckbox>
              </th>
            } @else if (selectionMode == 'single') {
              <th pFrozenColumn [frozen]="false" [alignFrozen]="'left'" class="w-3rem max-w-3rem"></th>
            }
          } @else {
            <th></th>
          }
          @if (groupRowsBy && groupColumn && rowGroupMode == 'rowspan') {
            <th
              class="border-noround white-space-nowrap"
              pFrozenColumn
              [frozen]="groupColumn?.frozen"
              [alignFrozen]="groupColumn?.alignFrozen"
              [pSortableColumn]="groupColumn?.key"
              [pSortableColumnDisabled]="!!groupColumn?.sortDisabled"
              pReorderableColumn
              pResizableColumn
              [style.cursor]="reorderableColumns ? 'move !important' : 'pointer !important'"
            >
              <div class="flex justify-content-between align-items-center h-full custom-filter flex-grow-1">
                <!-- @TODO: Add component to handle dropdown, multiDropdown and slider -->
                <div class="flex flex-row align-items-center gap-1 flex-grow-1">
                  @if (!!!groupColumn.sortDisabled) {
                    <p-sortIcon [field]="groupColumn.key"></p-sortIcon>
                  }
                  <span class="flex w-full gap-2 justify-content-between flex-grow-1 align-items-baseline">
                    <span>
                      {{ groupColumn.name | humanize }}
                    </span>
                    @if (reorderableColumns) {
                      <i class="pi pi-arrows-alt"></i>
                    }
                  </span>
                </div>
                @if (!!!groupColumn.sortDisabled) {
                  <app-table-row-group-switch
                    [col]="groupColumn"
                    (onRowGroup)="selectRowGroupingMode($event)"
                  ></app-table-row-group-switch>
                }
                @if (groupColumn.filter) {
                  <app-filter-switch [col]="groupColumn" [tagsControl]="tagsFilterControl"></app-filter-switch>
                }
              </div>
            </th>
          }
          @for (
            col of displayedColumnsModel?.length > 0 ? displayedColumnsModel : columns;
            track col;
            let ind = $index
          ) {
            @if (col.key != groupColumn?.key) {
              <th
                class="border-noround white-space-nowrap"
                [class.max-w-12rem]="col.key === 'code'"
                [class.w-12rem]="col.key === 'code'"
                pFrozenColumn
                [frozen]="col.frozen"
                [alignFrozen]="col.alignFrozen"
                [pSortableColumn]="col.key"
                [pSortableColumnDisabled]="!!col.sortDisabled"
                pReorderableColumn
                pResizableColumn
                [style.cursor]="reorderableColumns ? 'move !important' : 'pointer !important'"
              >
                <div class="flex justify-content-between align-items-center h-full custom-filter flex-grow-1">
                  <!-- @TODO: Add component to handle dropdown, multiDropdown and slider -->
                  <div class="flex flex-row align-items-center gap-1 flex-grow-1">
                    @if (!!!col.sortDisabled) {
                      <p-sortIcon [field]="col.key"></p-sortIcon>
                    }
                    <span class="flex w-full gap-2 justify-content-between flex-grow-1 align-items-baseline">
                      <span>
                        {{ col.name | humanize }}
                      </span>
                      @if (reorderableColumns) {
                        <i class="pi pi-arrows-alt"></i>
                      }
                    </span>
                  </div>
                  @if (!!!col.sortDisabled) {
                    <app-table-row-group-switch
                      [col]="col"
                      (onRowGroup)="selectRowGroupingMode($event)"
                    ></app-table-row-group-switch>
                  }
                  @if (col.filter) {
                    <app-filter-switch [col]="col" [tagsControl]="tagsFilterControl"></app-filter-switch>
                  }
                </div>
              </th>
            }
          }
          @if (tableActions.length > 0) {
            <th
              class="border-noround"
              pFrozenColumn
              [frozen]="actionCol.frozen"
              [alignFrozen]="actionCol.alignFrozen"
              [style]="'right:0; ' + (tableActionsStyle ? tableActionsStyle : '')"
              class="max-w-16rem w-16rem right-0 left-0"
            >
              <div class="flex justify-content-center align-items-center h-full">
                {{ actionCol.name | translate }}
              </div>
            </th>
          }
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-columns="columns"
        let-data
        let-rowIndex="rowIndex"
        let-rowgroup="rowgroup"
        let-rowspan="rowspan"
      >
        <!-- <tr
      [pSelectableRow]="data"
      [pSelectableRowIndex]="rowIndex"
      > -->
        <tr>
          @if (selectionMode == 'multiple') {
            <td pFrozenColumn [frozen]="false" [alignFrozen]="'left'">
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <p-tableCheckbox
                  [inputId]="'table-checkbox-' + (data?.code || data?.id || data?.name)"
                  [value]="data"
                ></p-tableCheckbox>
              }
            </td>
          } @else if (selectionMode == 'single') {
            <td pFrozenColumn [frozen]="false" [alignFrozen]="'left'">
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <p-tableRadioButton
                  [inputId]="'table-checkbox-' + (data?.code || data?.id || data?.name)"
                  [value]="data"
                ></p-tableRadioButton>
              }
            </td>
          }
          @if (rowgroup && groupColumn && rowGroupMode == 'rowspan') {
            <td
              [attr.rowspan]="rowspan"
              pFrozenColumn
              [frozen]="groupColumn.frozen"
              class="border-noround"
              style="min-width: 11rem"
            >
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <div class="w-full max-h-5rem overflow-y-auto">
                  <!-- TODO: @Nabeeh check this part out i added it so the cell wont overflow outside -->
                  <app-view-switch
                    [viewType]="groupColumn.dataType"
                    [data]="groupColumn.passRowAsData ? data : getElementValue(groupColumn.key, data)"
                    [options]="getOptions(groupColumn.options, groupColumn.dataType)"
                    [translationKeyPrefix]="groupColumn.translationKeyPrefix"
                  >
                  </app-view-switch>
                </div>
              }
            </td>
          }
          @for (
            col of displayedColumnsModel?.length > 0 ? displayedColumnsModel : columns;
            track col;
            let ind = $index
          ) {
            @if (col.key != groupColumn?.key) {
              <td
                pFrozenColumn
                [frozen]="col.frozen"
                class="border-noround"
                [class.max-w-12rem]="col.key === 'code'"
                [class.w-12rem]="col.key === 'code'"
                [style]="
                  'min-width: 11rem; ' +
                  ((displayedColumnsModel?.length > 0 ? displayedColumnsModel : columns)?.length > 6
                    ? 'max-width: 24rem;'
                    : '')
                "
              >
                @if (skeletonLoader && isLoading) {
                  <p-skeleton height="20px" />
                } @else {
                  <span class="p-column-title mr-2">{{ col?.name }}</span>
                  @if (ind == 0) {
                    <div class="flex w-full h-full gap-2">
                      <!-- <p-tableCheckbox *ngIf="selectionMode == 'multiple'" [value]="data"></p-tableCheckbox> -->
                      <ng-container *ngTemplateOutlet="appViewSwitch1"></ng-container>
                    </div>
                  } @else {
                    <div class="w-full max-h-5rem overflow-y-auto">
                      <!-- TODO: @Nabeeh check this part out i added it so the cell wont overflow outside -->
                      <app-view-switch
                        [viewType]="col.dataType"
                        [data]="col.passRowAsData ? data : getElementValue(col.key, data)"
                        [options]="getOptions(col.options, col.dataType)"
                        [translationKeyPrefix]="col.translationKeyPrefix"
                      >
                      </app-view-switch>
                    </div>
                  }
                  <ng-template #appViewSwitch1>
                    <div class="w-full max-h-5rem overflow-y-auto">
                      <!-- TODO: @Nabeeh check this part out i added it so the cell wont overflow outside -->
                      <app-view-switch
                        [viewType]="col.dataType"
                        [data]="col.passRowAsData ? data : getElementValue(col.key, data)"
                        [options]="getOptions(col.options, col.dataType)"
                        [translationKeyPrefix]="col.translationKeyPrefix"
                      >
                      </app-view-switch>
                    </div>
                  </ng-template>
                }
              </td>
            }
          }
          @if (tableActions.length > 0) {
            <td
              pFrozenColumn
              [frozen]="actionCol.frozen"
              [alignFrozen]="actionCol.alignFrozen"
              class="border-noround white-space-nowrap right-0 left-0"
              [style]="'min-width: 6.5rem; right:0; ' + (tableActionsStyle ? tableActionsStyle : '')"
            >
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="32px" />
              } @else {
                @if (customActionTemplate) {
                  <ng-container *ngTemplateOutlet="customActionTemplate; context: { $implicit: data }"></ng-container>
                }
                <app-button-list
                  class="w-full"
                  [itemClass]="'w-full'"
                  [actionList]="tableActions"
                  [listType]="tableActionsListType"
                  [commandData]="passIndexWithRowDataToAction ? { data: data, index: rowIndex } : data"
                  [displayData]="data"
                  [listTypeItemClass]="'w-fit'"
                  [appendTo]="'body'"
                ></app-button-list>
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        @if (skeletonLoader && isLoading) {
        } @else {
          <tr>
            <td colspan="100">{{ 'general.table.noItemsFound' | translate }}</td>
          </tr>
        }
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <!-- <tr>
          <td colspan="100">Loading data. Please wait.</td>
        </tr> -->
      </ng-template>
    </p-table>
  }
  @if (groupingMode == 'expand') {
    <p-table
      #dt1
      [value]="skeletonLoader && isLoading ? emptyItemArray : inputData"
      [dataKey]="dataKey"
      [loading]="skeletonLoader ? false : isLoading"
      [rowHover]="true"
      [paginatorDropdownAppendTo]="'body'"
      styleClass=""
      [globalFilterFields]="[globalFilters.join('')]"
      responsiveLayout="scroll"
      [breakpoint]="'960px'"
      [scrollable]="true"
      [paginator]="offlineConfig.paginator"
      [rows]="pageInfo.pagination.size"
      [totalRecords]="pageInfo.totalElements"
      [lazy]="offlineConfig.lazy"
      (onLazyLoad)="loadData($event)"
      [lazyLoadOnInit]="lazyLoadOnInit"
      [showCurrentPageReport]="true"
      [filterDelay]="600"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="rowsPerPageOptions"
      [selectionMode]="selectionMode"
      [(selection)]="selectedItems"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      stateStorage="session"
      [stateKey]="true ? undefined : sessionStorageKey"
      [reorderableColumns]="reorderableColumns"
      (onColReorder)="onColReorder($event)"
      [columns]="cols"
      [resizableColumns]="resizableColumns"
      [class.disable-column-resize]="!resizableColumns"
      columnResizeMode="expand"
      [styleClass]="'justify-content-start flex flex-column h-full'"
      sortMode="multiple"
      [groupRowsBy]="groupRowsBy"
      [rowGroupMode]="rowGroupMode"
    >
      <ng-template pTemplate="groupheader" let-data let-rowIndex="rowIndex" let-expanded="expanded">
        <tr>
          <td [attr.colspan]="displayedColumnsModel?.length > 0 ? displayedColumnsModel?.length : cols?.length">
            <div class="flex flex-row gap-2">
              @if (groupingMode == 'expand') {
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="data"
                  class="p-button-text p-button-rounded p-button-plain mr-2"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                ></button>
              }
              <app-view-switch
                [viewType]="groupColumn.dataType"
                [data]="groupColumn.passRowAsData ? data : getElementValue(groupColumn.key, data)"
                [options]="getOptions(groupColumn.options, groupColumn.dataType)"
                [translationKeyPrefix]="groupColumn.translationKeyPrefix"
              >
              </app-view-switch>
            </div>
          </td>
        </tr>
      </ng-template>
      @if (offlineConfig.showActionBar) {
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between flex-column sm:flex-row gap-2">
            @if (showControlActions) {
              <div class="flex flex-row gap-2 flex-wrap">
                <ng-content select="[left-header]"></ng-content>
                @if (pageActions.length > 0) {
                  <div>
                    @if (pageActions.length > 0) {
                      <app-button-list
                        class="align-self-center"
                        [listType]="listType"
                        [actionList]="pageActions"
                        [commandData]="pageActionsCommandData"
                        [appendTo]="null"
                      ></app-button-list>
                    }
                  </div>
                }
                <!-- <app-button
            pTooltip="Update All Records"
            tooltipPosition="top"
            [commandData]="dt1"
                                [action]="{
                                    id: 99,
                                    label: 'Update Records',
                                    icon: 'pi pi-file-edit',
                                    iconPos: 'left',
                                    command: openEditDialog.bind(this),
                                    buttonStyle: 'outlined'
                                }"
          ></app-button> -->
                <ng-container *ngxPermissionsOnly="settingsButtonPermission">
                  @if (settingsUrl) {
                    <a [routerLink]="settingsUrl">
                      <app-button
                        style="display: block"
                        pTooltip="Edit Module Settings"
                        tooltipPosition="top"
                        [action]="{
                          id: 199,
                          label: '',
                          icon: 'pi pi-cog',
                          iconPos: 'left',
                          buttonStyle: 'raised',
                          color: 'secondary',
                        }"
                      ></app-button>
                    </a>
                  }
                  @if (showSwapPageMode) {
                    <app-button
                      style="display: block"
                      pTooltip="Change Manage Mode"
                      tooltipPosition="top"
                      [action]="{
                        id: 199,
                        label: '',
                        icon: 'pi pi-trash',
                        iconPos: 'left',
                        buttonStyle: 'raised',
                        color: 'secondary',
                        command: swapPageMode.bind(this),
                      }"
                    ></app-button>
                  }
                </ng-container>
                <app-button
                  pTooltip="Refresh"
                  tooltipPosition="top"
                  [commandData]="dt1"
                  [action]="{
                    id: 99,
                    label: '',
                    icon: 'pi pi-refresh',
                    iconPos: 'left',
                    command: reload.bind(this),
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <app-button
                  pTooltip="Clear Filters, Selection"
                  tooltipPosition="top"
                  [commandData]="dt1"
                  [action]="{
                    id: 1,
                    label: '',
                    icon: 'pi pi-filter-slash',
                    iconPos: 'left',
                    command: clear.bind(this),
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <app-button
                  pTooltip="Select Table Columns"
                  tooltipPosition="top"
                  (onClickAction)="op.toggle($event)"
                  [action]="{
                    id: 2,
                    label: '',
                    icon: 'pi pi-eye',
                    iconPos: 'left',
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <!-- <app-button
          *ngIf="!!!selectedItems.length"
          (onClickAction)="exportop.toggle($event)"
          pTooltip="Export Table"
          tooltipPosition="top"
                                [action]="{
                                    id: 1,
                                    label: 'Export',
                                    icon: 'pi pi-file-export',
                                    iconPos: 'left',
                                    buttonStyle: 'outlined',
                                    color:'secondary'
                                }"
        ></app-button>
        <app-button
          *ngIf="!!selectedItems.length"
          (onClickAction)="exportItemsop.toggle($event)"
          pTooltip="Export Selected Items"
          tooltipPosition="top"
                                [action]="{
                                    id: 1,
                                    label: 'Export Selected',
                                    icon: 'pi pi-file-export',
                                    iconPos: 'left',
                                    buttonStyle: 'outlined',
                                    color:'secondary'
                                }"
        ></app-button> -->
                @if (!reorderableColumns) {
                  <app-button
                    (onClickAction)="toggleReorder(true)"
                    pTooltip="Enable Reorder Columns"
                    tooltipPosition="top"
                    [action]="{
                      id: 1,
                      label: '',
                      icon: 'pi pi-arrows-alt',
                      iconPos: 'left',
                      buttonStyle: 'raised',
                      color: 'secondary',
                    }"
                  ></app-button>
                }
                @if (reorderableColumns) {
                  <app-button
                    (onClickAction)="toggleReorder(false)"
                    pTooltip="Disable Reorder Columns"
                    tooltipPosition="top"
                    [action]="{
                      id: 1,
                      label: '',
                      icon: 'pi pi-sort-alt-slash',
                      iconPos: 'left',
                      buttonStyle: 'raised',
                      color: 'secondary',
                    }"
                  ></app-button>
                }
                <app-button
                  pTooltip="Custom Number of Rows"
                  tooltipPosition="top"
                  [commandData]="dt1"
                  [action]="{
                    id: 1,
                    label: '',
                    icon: 'pi pi-list',
                    iconPos: 'left',
                    command: customRows.bind(this),
                    buttonStyle: 'raised',
                    color: 'secondary',
                  }"
                ></app-button>
                <p-overlayPanel #op [showCloseIcon]="true">
                  <ng-template pTemplate>
                    <p-listbox
                      [options]="dropdownCols"
                      [checkbox]="true"
                      [filter]="true"
                      [multiple]="true"
                      [ngModel]="displayedColumnsModel"
                      (onChange)="onSelectionChange($event)"
                      optionLabel="name"
                      [optionValue]="'value'"
                      [style]="{ width: '15rem' }"
                    >
                    </p-listbox>
                  </ng-template>
                </p-overlayPanel>
                <p-overlayPanel #exportop [showCloseIcon]="true" [styleClass]="'w-14rem'">
                  <ng-template pTemplate>
                    <app-export-data-form
                      (formSubmit)="exp($event)"
                      [totalElements]="pageInfo.totalElements"
                    ></app-export-data-form>
                  </ng-template>
                </p-overlayPanel>
                <p-overlayPanel #exportItemsop [showCloseIcon]="true" [styleClass]="'w-14rem'">
                  <ng-template pTemplate>
                    <app-export-selected-data-form
                      (formSubmit)="exportSelectedItems($event)"
                    ></app-export-selected-data-form>
                  </ng-template>
                </p-overlayPanel>
              </div>
            }
            <ng-content select="[right-header]"></ng-content>
            @if (showSearchField) {
              <div class="flex flex-row gap-2">
                <span class="p-input-icon-left mb-2 h-fit">
                  <i class="pi pi-search"></i>
                  <input
                    appMaxCharacterValidator
                    pInputText
                    type="text"
                    #globalFilter
                    (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                    [placeholder]="'general.fields.searchKeyword.label' | translate: 'Search Keyword'"
                    class="w-full"
                    name="search-keyword"
                    id="search-keyword"
                  />
                </span>
                @if (showTagsSearch) {
                  <span class="p-input-icon-left mb-2 h-fit">
                    <app-tag-search-input
                      [viewMode]="'create'"
                      [name]="'tags'"
                      [placeholder]="'general.fields.tags.label' | translate: 'Tags'"
                      [controls]="tagsControlsObj"
                    ></app-tag-search-input>
                  </span>
                }
              </div>
            }
          </div>
        </ng-template>
      }
      <ng-template pTemplate="header" let-columns>
        <tr [style]="headerBackground ? 'background:var(--primary-bg-400);' : ''">
          @if (showSelectAll) {
            @if (selectionMode == 'multiple') {
              <th pFrozenColumn [frozen]="false" [alignFrozen]="'left'" class="w-3rem max-w-3rem">
                <p-tableHeaderCheckbox [inputId]="'table-header-checkbox'"></p-tableHeaderCheckbox>
              </th>
            } @else if (selectionMode == 'single') {
              <th pFrozenColumn [frozen]="false" [alignFrozen]="'left'" class="w-3rem max-w-3rem"></th>
            }
          } @else {
            <th></th>
          }
          @if (groupRowsBy && rowGroupMode == 'rowspan') {
            <th>Group</th>
          }

          @for (
            col of displayedColumnsModel?.length > 0 ? displayedColumnsModel : columns;
            track col;
            let ind = $index
          ) {
            <th
              class="border-noround white-space-nowrap"
              [class.max-w-12rem]="col.key === 'code'"
              [class.w-12rem]="col.key === 'code'"
              pFrozenColumn
              [frozen]="col.frozen"
              [alignFrozen]="col.alignFrozen"
              [pSortableColumn]="col.key"
              [pSortableColumnDisabled]="!!col.sortDisabled"
              pReorderableColumn
              pResizableColumn
              [style.cursor]="reorderableColumns ? 'move !important' : 'pointer !important'"
            >
              <div class="flex justify-content-between align-items-center h-full custom-filter flex-grow-1">
                <!-- @TODO: Add component to handle dropdown, multiDropdown and slider -->
                <div class="flex flex-row align-items-center gap-1 flex-grow-1">
                  @if (!!!col.sortDisabled) {
                    <p-sortIcon [field]="col.key"></p-sortIcon>
                  }
                  <span class="flex w-full gap-2 justify-content-between flex-grow-1 align-items-baseline">
                    <span>
                      {{ col.name | humanize }}
                    </span>
                    @if (reorderableColumns) {
                      <i class="pi pi-arrows-alt"></i>
                    }
                  </span>
                </div>
                @if (!!!col.sortDisabled) {
                  <app-table-row-group-switch
                    [col]="col"
                    (onRowGroup)="selectRowGroupingMode($event)"
                  ></app-table-row-group-switch>
                }
                @if (col.filter) {
                  <app-filter-switch [col]="col" [tagsControl]="tagsFilterControl"></app-filter-switch>
                }
              </div>
              <!-- <div class="flex gap-2 h-full w-full align-items-center">
          <p-tableHeaderCheckbox *ngIf="ind == 0 && selectionMode == 'multiple'" ></p-tableHeaderCheckbox>
          <div                             [pSortableColumn]="col.key"
            [pSortableColumnDisabled]="!!col.sortDisabled"
            class="flex justify-content-between align-items-center h-full custom-filter flex-grow-1"
            >
            @TODO: Add component to handle dropdown, multiDropdown and slider
            <div
              class="flex flex-row align-items-center gap-1"
              >
              <p-sortIcon *ngIf="!!!col.sortDisabled" [field]="col.key"></p-sortIcon>
              {{ col.name }}
            </div>
            <app-filter-switch
              *ngIf="col.filter"
              [col]="col"
            ></app-filter-switch>
          </div>
        </div> -->
            </th>
          }
          @if (tableActions.length > 0) {
            <th
              class="border-noround"
              pFrozenColumn
              [frozen]="actionCol.frozen"
              [alignFrozen]="actionCol.alignFrozen"
              [style]="'right:0; ' + (tableActionsStyle ? tableActionsStyle : '')"
              class="max-w-16rem w-16rem right-0 left-0"
            >
              <div class="flex justify-content-center align-items-center h-full">
                {{ actionCol.name | translate }}
              </div>
            </th>
          }
        </tr>
      </ng-template>
      <ng-template
        pTemplate="rowexpansion"
        let-columns="columns"
        let-data
        let-rowIndex="rowIndex"
        let-rowgroup="rowgroup"
        let-rowspan="rowspan"
      >
        <!-- <tr
  [pSelectableRow]="data"
  [pSelectableRowIndex]="rowIndex"
  > -->
        <tr>
          @if (selectionMode == 'multiple') {
            <td pFrozenColumn [frozen]="false" [alignFrozen]="'left'">
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <p-tableCheckbox
                  [inputId]="'table-checkbox-' + (data?.code || data?.id || data?.name)"
                  [value]="data"
                ></p-tableCheckbox>
              }
            </td>
          } @else if (selectionMode == 'single') {
            <td pFrozenColumn [frozen]="false" [alignFrozen]="'left'">
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <p-tableRadioButton
                  [inputId]="'table-checkbox-' + (data?.code || data?.id || data?.name)"
                  [value]="data"
                ></p-tableRadioButton>
              }
            </td>
          }
          @if (rowgroup && groupColumn && rowGroupMode == 'rowspan') {
            <td
              [attr.rowspan]="rowspan"
              pFrozenColumn
              [frozen]="groupColumn.frozen"
              class="border-noround"
              style="min-width: 11rem"
            >
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <app-view-switch
                  [viewType]="groupColumn.dataType"
                  [data]="groupColumn.passRowAsData ? data : getElementValue(groupColumn.key, data)"
                  [options]="getOptions(groupColumn.options, groupColumn.dataType)"
                  [translationKeyPrefix]="groupColumn.translationKeyPrefix"
                >
                </app-view-switch>
              }
            </td>
          }
          @for (
            col of displayedColumnsModel?.length > 0 ? displayedColumnsModel : columns;
            track col;
            let ind = $index
          ) {
            <td
              pFrozenColumn
              [frozen]="col.frozen"
              class="border-noround"
              [class.max-w-12rem]="col.key === 'code'"
              [class.w-12rem]="col.key === 'code'"
              [style]="
                'min-width: 11rem; ' +
                ((displayedColumnsModel?.length > 0 ? displayedColumnsModel : columns)?.length > 6
                  ? 'max-width: 24rem;'
                  : '')
              "
            >
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="20px" />
              } @else {
                <span class="p-column-title mr-2">{{ col?.name }}</span>
                @if (ind == 0) {
                  <div class="flex w-full h-full gap-2">
                    <!-- <p-tableCheckbox *ngIf="selectionMode == 'multiple'" [value]="data"></p-tableCheckbox> -->
                    <ng-container *ngTemplateOutlet="appViewSwitch2"></ng-container>
                  </div>
                } @else {
                  <div class="w-full max-h-5rem overflow-y-auto">
                    <!-- TODO: @Nabeeh check this part out i added it so the cell wont overflow outside -->
                    <app-view-switch
                      [viewType]="col.dataType"
                      [data]="col.passRowAsData ? data : getElementValue(col.key, data)"
                      [options]="getOptions(col.options, groupColumn.dataType)"
                      [translationKeyPrefix]="col.translationKeyPrefix"
                    >
                    </app-view-switch>
                  </div>
                }
                <ng-template #appViewSwitch2>
                  <div class="w-full max-h-5rem overflow-y-auto">
                    <!-- TODO: @Nabeeh check this part out i added it so the cell wont overflow outside -->
                    <app-view-switch
                      [viewType]="col.dataType"
                      [data]="col.passRowAsData ? data : getElementValue(col.key, data)"
                      [options]="getOptions(col.options, groupColumn.dataType)"
                      [translationKeyPrefix]="col.translationKeyPrefix"
                    >
                    </app-view-switch>
                  </div>
                </ng-template>
              }
            </td>
          }
          @if (tableActions.length > 0) {
            <td
              pFrozenColumn
              [frozen]="actionCol.frozen"
              [alignFrozen]="actionCol.alignFrozen"
              class="border-noround white-space-nowrap right-0 left-0"
              [style]="'min-width: 6.5rem; right:0; ' + (tableActionsStyle ? tableActionsStyle : '')"
            >
              @if (skeletonLoader && isLoading) {
                <p-skeleton height="32px" />
              } @else {
                @if (customActionTemplate) {
                  <ng-container *ngTemplateOutlet="customActionTemplate; context: { $implicit: data }"></ng-container>
                }
                <app-button-list
                  class="w-full"
                  [itemClass]="'w-full'"
                  [actionList]="tableActions"
                  [listType]="tableActionsListType"
                  [commandData]="passIndexWithRowDataToAction ? { data: data, index: rowIndex } : data"
                  [displayData]="data"
                  [listTypeItemClass]="'w-fit'"
                  [appendTo]="'body'"
                ></app-button-list>
              }
            </td>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        @if (skeletonLoader && isLoading) {
        } @else {
          <tr>
            <td colspan="100">{{ 'general.table.noItemsFound' | translate }}</td>
          </tr>
        }
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <!-- <tr>
          <td colspan="100">Loading data. Please wait.</td>
        </tr> -->
      </ng-template>
    </p-table>
  }
</div>
<p-dialog
  header="Export Data"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="true"
  [(visible)]="exportDataDialog"
>
  <app-export-data-form
    (formSubmit)="exp($event); exportDataDialog = false"
    [totalElements]="pageInfo.totalElements"
  ></app-export-data-form>
</p-dialog>
<p-dialog
  header="Export Selected Items"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="true"
  [(visible)]="exportSelectedItemsDialog"
>
  <app-export-selected-data-form
    (formSubmit)="exportSelectedItems($event); exportSelectedItemsDialog = false"
  ></app-export-selected-data-form>
</p-dialog>
<p-dialog
  header="Export Package"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="true"
  [(visible)]="exportPackageDialog"
>
  @if (exportPackageDialog) {
    <app-export-package-form
      (formSubmit)="expPackage($event); exportPackageDialog = false"
      [totalElements]="pageInfo.totalElements"
      [moduleKeyword]="moduleKeyword"
      [selectedItems]="selectedItems"
    ></app-export-package-form>
  }
</p-dialog>
