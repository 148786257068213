import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { TimeInputComponent } from '@shared/components/ui/input-fields/time-input/time-input.component';

@Component({
  selector: 'app-timeline-filter',
  templateUrl: './timeline-filter.component.html',
  styleUrl: './timeline-filter.component.scss'
})
export class TimelineFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }
  initInput() {
    this.dropDownInput = {
      componentType: TimeInputComponent,
      options: {
        name: 'timeline',
        label: '',
        placeholder: '',
        control: new FormControl(null),
      },
    };
  }
}
