import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseListResponse, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GenericInfoDataService extends BaseRequestControllerService<any> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.dataStructure,
      '/generic-info',
      [
        'id',
        'code',
        'tags',
        'tagsCodes',
        'lockedBy',
        'lockedForEdit',
        'lockedUntil',
        'recordStatus',
        'translations',
        'originalLanguage',
        'creatorName',
        'lastModifierName',
        'creationDate',
        'lastModificationDate',
      ],
      null
    );
  }
  getTypes(options: RequestHandlerOptions = new RequestHandlerOptions()): Observable<BaseListResponse<any>> {
    return this.dataService.postData(`${this.url}/types`, null, options) as Observable<BaseListResponse<any>>;
  }
}
