<div class="formgrid grid p-fluid gap-2">
  <div class="col-12">
    <app-tag-search-input
      [viewMode]="'create'"
      [name]="'tags'"
      placeholder="Tags"
      [controls]="tagsObject"
      [addButton]="true"
    ></app-tag-search-input>
  </div>
  <div class="flex col-12 flex-row-reverse gap-2">
    <app-button [action]="saveButton"></app-button>
    <app-button [action]="cancelButton"></app-button>
  </div>
</div>
